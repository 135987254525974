exports.convPrismicLng = function(lng) {
  let r = "en-gb"
  switch (lng) {
    case 'zh-HK':
    case 'zh-hk':
      r = "zh-hk"
      break;
    case 'zh-CN':
    case 'zh-cn':
      r = "zh-cn"
      break;
    default:
      r = "en-gb"
  }
  return r
}
