import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
    Jumbotron,Container, Row, Col
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link, Language } from '@sckit/gatsby-plugin-i18next'

import LocalImage from '../components/local-image'
import Layout from '../components/layout'
import FeatureBox from '../components/feature-box'

import './../css/home.css'

class IndexPage extends Component {
    // constructor(props) {
    //     super(props);

    //     const small =   'home_top_768.jpg';
    //     const medium =  'home_top_1024.jpg';
    //     const large =   'home_top_1920.jpg';

    //     // Don't call this.setState() here!
    //     // let postsToShow = 10
    //     // let _pts = 10
    //     // if (typeof window !== `undefined`) {
    //     //     postsToShow = window.productsPostsToShow
    //     //     _pts = window.productsPostsToShow
    //     // }
    // }
    render() {

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                return (
                    <Layout>
                        <div id="bodyHome" className="body-page">
                            <main role="main">

                                {/*--=============  Landing Area  =============--*/}
                                {/* <Jumbotron fluid> */}
                                <Jumbotron fluid>
                                    {/* <img src='home_top_768.jpg' srcSet={`home_top_768.jpg 768w, home_top_1024 1024w, home_top_1920 1920w`} /> */}
                                    {/* <LocalImage name="home_top_768" attr={{className: "img-fluid d-md-none", alt:"The War Under Pollution",
                                        srcSet:"home_top_768.jpg 300w, home_top_1024 1024w, home_top_1920 1920w" }} /> */}

                                    <LocalImage name="home_top_768" attr={{className: "img-fluid d-block d-md-none", alt:"The War Under Pollution"}} />
                                    <LocalImage name="home_top_1024" attr={{className: "img-fluid d-none d-md-block d-xl-none", alt:"The War Under Pollution"}} />
                                    <LocalImage name="home_top_1920" attr={{className: "img-fluid d-none d-xl-block w-100", alt:"The War Under Pollution"}} />
                                    {/* <Container>
                                        <div className="title">The Taste of Coffee </div>
                                        <a href="#TopStory"><button type="button" className="btn">Watch Trailer</button></a>
                                    </Container> */}
                                </Jumbotron>
                                {/*--=============  / LANDING Area  =============--*/}


                                {/*--=============  FEATURES Boxes  =============--*/}
                                <Language>
                                { lngProps => <FeatureBox limit={6} displayIn={2} lng={lngProps.lng} /> }
                                </Language>
                                {/*--=============  / FEATURES Boxes  =============--*/}

                                {/*--=============  HIGHLIGHT Area  =============--*/}

                                <div id="highlight"><Container className="wrapper">

                                    <Row className="hl_box hl_world">
                                        <Col col={12} md={{offset:6,span:6}} className="align-self-center">
                                            <div className="title">
                                                {t('TEXT000005')}
                                            </div>
                                            <div className="desp">
                                                {t('TEXT000110')}
                                            </div>
                                            <div className="more">
                                                <Link to={`/setting`}>
                                                    {t('TEXT000011')}
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="hl_box hl_factions">
                                        <Col col={12} md={6} className="align-self-center">
                                            <div className="title">{t('TEXT000026')}</div>
                                            <div className="desp">
                                                {t('TEXT000133')}
                                            </div>
                                            <div className="more">
                                                <Link to={`/database/faction`}>
                                                    {t('TEXT000011')}
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="hl_box hl_kit">
                                        <Col col={12} md={{offset:6,span:6}} className="align-self-center">
                                            <div className="title">{t('TEXT000101')}</div>
                                            <div className="desp">
                                                {t('TEXT000102')}
                                            </div>
                                            <div className="more">
                                                <Link to={`/creator`}>
                                                    {t('TEXT000011')}
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>


                                </Container></div>

                                {/*--=============  / HIGHLIGHT Area  =============--*/}

                            </main>
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(IndexPage)

export const query = graphql`
    query getHomeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
